import React from 'react'

const Instagram = ({ size = '24px', color = "#323232"}) => (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.60156 3.75C5.93848 3.75 3.75 5.93555 3.75 8.60156V15.3984C3.75 18.0615 5.93555 20.25 8.60156 20.25H15.3984C18.0615 20.25 20.25 18.0645 20.25 15.3984V8.60156C20.25 5.93848 18.0645 3.75 15.3984 3.75H8.60156ZM8.60156 5.25H15.3984C17.2529 5.25 18.75 6.74707 18.75 8.60156V15.3984C18.75 17.2529 17.2529 18.75 15.3984 18.75H8.60156C6.74707 18.75 5.25 17.2529 5.25 15.3984V8.60156C5.25 6.74707 6.74707 5.25 8.60156 5.25ZM16.4297 6.89062C16.0518 6.89062 15.75 7.19238 15.75 7.57031C15.75 7.94824 16.0518 8.25 16.4297 8.25C16.8076 8.25 17.1094 7.94824 17.1094 7.57031C17.1094 7.19238 16.8076 6.89062 16.4297 6.89062ZM12 7.5C9.52441 7.5 7.5 9.52441 7.5 12C7.5 14.4756 9.52441 16.5 12 16.5C14.4756 16.5 16.5 14.4756 16.5 12C16.5 9.52441 14.4756 7.5 12 7.5ZM12 9C13.667 9 15 10.333 15 12C15 13.667 13.667 15 12 15C10.333 15 9 13.667 9 12C9 10.333 10.333 9 12 9Z" fill={color}/>
</svg>


  );

  export default Instagram