import React from 'react'

const Newyork = ({ size = '24px', color = "#323232"}) => (
    <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M22.5 18L21.75 18.75H20.25L19.5 19.5H18.75V18.75L19.5 18L18.75 17.25V12.75L19.5 11.25V9L18.75 8.25V6.75L19.5 6V4.5L18.75 3.75V3H14.985L14.4315 3.1785L13.5 3.65625L12.8438 4.21875L12.375 4.78125L12 5.25L10.5 6V6.75L11.25 7.125V7.5L10.5 8.25L11.25 8.90625V9H10.278L9.75 9.75H8.25L7.5 10.5L6 9.75H4.5L3 10.5V11.25L3.75 11.625V12L3 12.1875L2.71875 12.75L2.0625 12.9375L1.728 13.5L0.75 14.25V15H12.75L14.25 15.75V16.5L15.75 18L18 18.75L17.25 19.5V20.3438L18 21H20.625L23.25 18.75L22.5 18ZM18 5.379L17.25 6.129V8.87175L18 9.62175V10.896L17.25 12.396V16.9193L16.56 16.689L15.75 15.879V14.823L13.104 13.5H4.02075L4.03275 13.4752L5.25 13.1715V11.25H5.646L7.146 12H8.12175L8.87175 11.25H10.5L10.9965 10.5H12.75V6.552L12.9653 6.444L13.1708 6.18675L13.527 5.74125L13.9155 5.2755L14.3415 4.91025L15.0068 4.569L15.2205 4.5H17.3783L18 5.121V5.379Z" fill={color}/>
</svg>



  );

  export default Newyork