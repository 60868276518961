/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState } from 'react'
import PropTypes from "prop-types"

import styled, { createGlobalStyle } from 'styled-components'
import { Link } from 'gatsby'
import WeddingLogo from './icons/logo'
import Hamburger from './icons/hamburger'
import Instagram from './icons/instagram'
import Couple from './icons/couple'
import Church from './icons/church'
import Plane from './icons/plane'
import Gift from './icons/gift'
import Newyork from './icons/ny'
import Party from './icons/party'

const GlobalStyle = createGlobalStyle`
    /* latin-ext */
    @font-face {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: local('Lato Regular'), local('Lato-Regular'), url(https://fonts.gstatic.com/s/lato/v16/S6uyw4BMUTPHjxAwXiWtFCfQ7A.woff2) format('woff2');
        unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    }
    /* latin */
    @font-face {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: local('Lato Regular'), local('Lato-Regular'), url(https://fonts.gstatic.com/s/lato/v16/S6uyw4BMUTPHjx4wXiWtFCc.woff2) format('woff2');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    }
    /* latin-ext */
    @font-face {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 700;
        font-display: swap;
        src: local('Lato Bold'), local('Lato-Bold'), url(https://fonts.gstatic.com/s/lato/v16/S6u9w4BMUTPHh6UVSwaPGQ3q5d0N7w.woff2) format('woff2');
        unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    }
    /* latin */
    @font-face {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 700;
        font-display: swap;
        src: local('Lato Bold'), local('Lato-Bold'), url(https://fonts.gstatic.com/s/lato/v16/S6u9w4BMUTPHh6UVSwiPGQ3q5d0.woff2) format('woff2');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    }
    /* latin-ext */
    @font-face {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 900;
        font-display: swap;
        src: local('Lato Black'), local('Lato-Black'), url(https://fonts.gstatic.com/s/lato/v16/S6u9w4BMUTPHh50XSwaPGQ3q5d0N7w.woff2) format('woff2');
        unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    }
    /* latin */
    @font-face {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 900;
        font-display: swap;
        src: local('Lato Black'), local('Lato-Black'), url(https://fonts.gstatic.com/s/lato/v16/S6u9w4BMUTPHh50XSwiPGQ3q5d0.woff2) format('woff2');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    }
    /* cyrillic-ext */
    @font-face {
        font-family: 'Source Serif Pro';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: local('Source Serif Pro'), local('SourceSerifPro-Regular'), url(https://fonts.gstatic.com/s/sourceserifpro/v8/neIQzD-0qpwxpaWvjeD0X88SAOeauXk-oAGIyY0Wfw.woff2) format('woff2');
        unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
    }
    /* cyrillic */
    @font-face {
        font-family: 'Source Serif Pro';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: local('Source Serif Pro'), local('SourceSerifPro-Regular'), url(https://fonts.gstatic.com/s/sourceserifpro/v8/neIQzD-0qpwxpaWvjeD0X88SAOeauXA-oAGIyY0Wfw.woff2) format('woff2');
        unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
    }
    /* greek */
    @font-face {
        font-family: 'Source Serif Pro';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: local('Source Serif Pro'), local('SourceSerifPro-Regular'), url(https://fonts.gstatic.com/s/sourceserifpro/v8/neIQzD-0qpwxpaWvjeD0X88SAOeauXc-oAGIyY0Wfw.woff2) format('woff2');
        unicode-range: U+0370-03FF;
    }
    /* vietnamese */
    @font-face {
        font-family: 'Source Serif Pro';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: local('Source Serif Pro'), local('SourceSerifPro-Regular'), url(https://fonts.gstatic.com/s/sourceserifpro/v8/neIQzD-0qpwxpaWvjeD0X88SAOeauXs-oAGIyY0Wfw.woff2) format('woff2');
        unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
    }
    /* latin-ext */
    @font-face {
        font-family: 'Source Serif Pro';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: local('Source Serif Pro'), local('SourceSerifPro-Regular'), url(https://fonts.gstatic.com/s/sourceserifpro/v8/neIQzD-0qpwxpaWvjeD0X88SAOeauXo-oAGIyY0Wfw.woff2) format('woff2');
        unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    }
    /* latin */
    @font-face {
        font-family: 'Source Serif Pro';
        font-style: normal;
        font-weight: 400;
        font-display: swap;
        src: local('Source Serif Pro'), local('SourceSerifPro-Regular'), url(https://fonts.gstatic.com/s/sourceserifpro/v8/neIQzD-0qpwxpaWvjeD0X88SAOeauXQ-oAGIyY0.woff2) format('woff2');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    }
    /* cyrillic-ext */
    @font-face {
        font-family: 'Source Serif Pro';
        font-style: normal;
        font-weight: 700;
        font-display: swap;
        src: local('Source Serif Pro Bold'), local('SourceSerifPro-Bold'), url(https://fonts.gstatic.com/s/sourceserifpro/v8/neIXzD-0qpwxpaWvjeD0X88SAOeasc8btSGqxKcsdrOPbQ.woff2) format('woff2');
        unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
    }
    /* cyrillic */
    @font-face {
        font-family: 'Source Serif Pro';
        font-style: normal;
        font-weight: 700;
        font-display: swap;
        src: local('Source Serif Pro Bold'), local('SourceSerifPro-Bold'), url(https://fonts.gstatic.com/s/sourceserifpro/v8/neIXzD-0qpwxpaWvjeD0X88SAOeasc8btSiqxKcsdrOPbQ.woff2) format('woff2');
        unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
    }
    /* greek */
    @font-face {
        font-family: 'Source Serif Pro';
        font-style: normal;
        font-weight: 700;
        font-display: swap;
        src: local('Source Serif Pro Bold'), local('SourceSerifPro-Bold'), url(https://fonts.gstatic.com/s/sourceserifpro/v8/neIXzD-0qpwxpaWvjeD0X88SAOeasc8btS-qxKcsdrOPbQ.woff2) format('woff2');
        unicode-range: U+0370-03FF;
    }
    /* vietnamese */
    @font-face {
        font-family: 'Source Serif Pro';
        font-style: normal;
        font-weight: 700;
        font-display: swap;
        src: local('Source Serif Pro Bold'), local('SourceSerifPro-Bold'), url(https://fonts.gstatic.com/s/sourceserifpro/v8/neIXzD-0qpwxpaWvjeD0X88SAOeasc8btSOqxKcsdrOPbQ.woff2) format('woff2');
        unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
    }
    /* latin-ext */
        @font-face {
        font-family: 'Source Serif Pro';
        font-style: normal;
        font-weight: 700;
        font-display: swap;
        src: local('Source Serif Pro Bold'), local('SourceSerifPro-Bold'), url(https://fonts.gstatic.com/s/sourceserifpro/v8/neIXzD-0qpwxpaWvjeD0X88SAOeasc8btSKqxKcsdrOPbQ.woff2) format('woff2');
        unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
    }
    /* latin */
    @font-face {
        font-family: 'Source Serif Pro';
        font-style: normal;
        font-weight: 700;
        font-display: swap;
        src: local('Source Serif Pro Bold'), local('SourceSerifPro-Bold'), url(https://fonts.gstatic.com/s/sourceserifpro/v8/neIXzD-0qpwxpaWvjeD0X88SAOeasc8btSyqxKcsdrM.woff2) format('woff2');
        unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
    }
    body {
        margin: 0px;
        font-family: 'Lato';
    }
    a {
        text-decoration: none
    }
    a:active {
        color: #323232
    }
`
const Nav = styled.div`
    height: 76px;
    background-color: #fff;
    border-bottom-right-radius: 16px;
    border-bottom-left-radius: 16px;
    box-shadow: 0px 3px 6px rgba(0,0,0,.15);
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    z-index: 9999;
    transition: box-shadow .4s
`

const MobileNav = styled.div`
    width: 100%;
    position: fixed;
    background-color: #fff;
    z-index: 999;
    height: 100%;
    left: -100%;
    transition: left .4s;
    padding-top: 86px;
    padding-left: 24px;
    box-sizing: border-box
`;

const MobileNavItem = styled(Link)`
    margin-top: 24px;
    color: #323232;
    fontSize: 16px;
    display: flex;
    align-items: center;
`

const HamburgerContainer = styled.div`
    position: absolute;
    height: 24px;
    width: 24px;
    left: 16px;
    top: 50%; 
    margin-top: -12px;
`;

const Footer = styled.div`
    padding: 24px;
    background-color: #e3e3e3;
    border-top-right-radius: 16px;
    border-top-left-radius: 16px;
    margin-top: 48px;
`

const Cont = styled.div`
    display: block;
    margin: auto;
    @media screen and (min-width: 800px) {
        max-width: 800px;
    } 
`

const LogoLink = styled(Link)`
    height: 48px;
    width: 180px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-top: -24px;
    margin-left: -90px;
`

const Layout = ({ children }) => {
  const [ isMobileNavOpen, setMobileNavOpen ] = useState(false);

  return (
    <>
      <GlobalStyle />
      <MobileNav style={ isMobileNavOpen ? {left: '0px'} : { left: '-100%' }}>
          <MobileNavItem href="/our-story"><Couple color="#666666" size="24"  /><span style={{marginLeft: '8px'}}>Our Story</span></MobileNavItem>
          <MobileNavItem href="/wedding-day"><Church color="#666666" size="24"  /><span style={{marginLeft: '8px'}}>Wedding Day</span></MobileNavItem>
          <MobileNavItem href="/travel"><Plane color="#666666" size="24"  /><span style={{marginLeft: '8px'}}>Travel</span></MobileNavItem>
          <MobileNavItem href="/registry"><Gift color="#666666" size="24" /><span style={{marginLeft: '8px'}}>Registry</span></MobileNavItem>
          <MobileNavItem href="/things-to-do"><Newyork color="#666666"  /><span style={{marginLeft: '8px'}}>Things To Do</span></MobileNavItem>
          <MobileNavItem href="/wedding-party"><Party color="#666666" size="24"  /><span style={{marginLeft: '8px'}}>Wedding Party</span></MobileNavItem>
      </MobileNav>
      <Nav style={!isMobileNavOpen ? {boxShadow: '0px 3px 6px rgba(0,0,0,.15)'} : { boxShadow: '0px 0px 0px rgba(0,0,0,0)'}}>
          <HamburgerContainer onClick={() => setMobileNavOpen(!isMobileNavOpen)}>
            <Hamburger />
          </HamburgerContainer>
          <LogoLink to="/"><WeddingLogo /></LogoLink>
      </Nav>
        <Cont>
        <main>{children}</main>
        </Cont>
        <Footer>
            <div style={{width: '24px', margin: 'auto', display: 'block'}}>
              <Instagram size="24px"/>
            </div>
            <a rel="noreferrer" style={{ marginTop: '12px', textAlign: 'center', fontSize: '16px', fontFamily: '"Lato", sans-serif', color: '#323232', display: 'block' }} href="https://www.instagram.com/explore/tags/anewpairofmittons/?hl=en" target="_blank">
                #ANewPairOfMittons
            </a>
            <a rel="noreferrer" style={{ textAlign: 'center', fontSize: '16px', marginTop: '8px', fontFamily: '"Lato", sans-serif', color: '#323232', display: 'block' }} href="https://www.instagram.com/explore/tags/samanthaissmitton/?hl=en" target="_blank">
                #SamanthaIsSmitton
            </a>
            <span style={{ marginTop: '24px', fontSize: '14px', fontFamily: '"Lato", sans-serif', textAlign: 'center', display: 'block' }}>Copyright &copy; {new Date().getFullYear()} Reinhart Mitton</span>
        </Footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
