import React from 'react'

const Gift = ({ size = '24px', color = "#323232"}) => (
    <svg width={size} height={size} viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.5 5.625C11.6499 5.625 10.125 7.1499 10.125 9C10.125 9.39551 10.2085 9.76904 10.3359 10.125H4.5V16.875H5.625V31.5H30.375V16.875H31.5V10.125H25.6641C25.7915 9.76904 25.875 9.39551 25.875 9C25.875 7.1499 24.3501 5.625 22.5 5.625C20.5312 5.625 19.1953 7.11914 18.3164 8.36719C18.1978 8.53418 18.1055 8.69678 18 8.85938C17.8945 8.69678 17.8022 8.53418 17.6836 8.36719C16.8047 7.11914 15.4688 5.625 13.5 5.625ZM13.5 7.875C14.2031 7.875 15.1172 8.63086 15.8203 9.63281C15.9917 9.87891 15.9653 9.89209 16.1016 10.125H13.5C12.8628 10.125 12.375 9.63721 12.375 9C12.375 8.36279 12.8628 7.875 13.5 7.875ZM22.5 7.875C23.1372 7.875 23.625 8.36279 23.625 9C23.625 9.63721 23.1372 10.125 22.5 10.125H19.8984C20.0347 9.89209 20.0083 9.87891 20.1797 9.63281C20.8828 8.63086 21.7969 7.875 22.5 7.875ZM6.75 12.375H29.25V14.625H19.125V13.5H16.875V14.625H6.75V12.375ZM7.875 16.875H28.125V29.25H19.125V18H16.875V29.25H7.875V16.875Z" fill={color}/>
    </svg>


  );

  export default Gift