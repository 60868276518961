import React from 'react'

const Church = ({ size = '24px', color = "#323232"}) => (
<svg width={size} height={size} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15 3V5H13V7H15V9.5625L11.2812 13.2812L11 13.5938V17.4062L4.5 21.125L5.5 22.875L7 22.0312V29H15V25C15 24.4453 15.4453 24 16 24C16.5547 24 17 24.4453 17 25V29H25V22.0312L26.5 22.875L27.5 21.125L21 17.4062V13.5938L20.7188 13.2812L17 9.5625V7H19V5H17V3H15ZM16 11.4375L19 14.4375V18.5938L19.5 18.875L23 20.875V27H19V25C19 23.3555 17.6445 22 16 22C14.3555 22 13 23.3555 13 25V27H9V20.875L12.5 18.875L13 18.5938V14.4375L16 11.4375ZM16 15C15.4492 15 15 15.4492 15 16V19H17V16C17 15.4492 16.5508 15 16 15Z" fill={color}/>
</svg>


  );

  export default Church